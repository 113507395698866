.tippy-box[data-theme~="lcrplus"] {
  color: #26323d;
  padding: 2px;
  border: 1px solid #ccc;
  box-shadow:
    0 0 10px 6px rgba(154, 161, 177, 0.15),
    0 4px 20px -8px rgba(36, 40, 47, 0.25),
    0 4px 2px -2px rgba(91, 94, 105, 0.15);
  background-color: white;
  border-radius: 8px;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
  font-size: 16px;

  &[data-placement^="top"] > .tippy-arrow::before {
    border-top-color: white;
  }

  &[data-placement^="bottom"] > .tippy-arrow::before {
    border-bottom-color: white;
  }

  &[data-placement^="left"] > .tippy-arrow::before {
    border-left-color: white;
  }

  &[data-placement^="right"] > .tippy-arrow::before {
    border-right-color: white;
  }

  > .tippy-backdrop {
    background-color: white;
  }

  > .tippy-svg-arrow {
    fill: white;
  }
}

.tippy-box[data-theme~="dark"] {
  color: #ffffff;
  padding: 0.35rem 0.35rem;
  border: 1px solid #ccc;
  box-shadow:
    0 0 10px 6px rgba(154, 161, 177, 0.15),
    0 4px 20px -8px rgba(36, 40, 47, 0.25),
    0 4px 2px -2px rgba(91, 94, 105, 0.15);
  background-color: #1f262d;
  border-radius: 6px;
  font-size: 12px;

  &[data-placement^="top"] > .tippy-arrow::before {
    border-top-color: #1f262d;
  }

  &[data-placement^="bottom"] > .tippy-arrow::before {
    border-bottom-color: #1f262d;
  }

  &[data-placement^="left"] > .tippy-arrow::before {
    border-left-color: #1f262d;
  }

  &[data-placement^="right"] > .tippy-arrow::before {
    border-right-color: #1f262d;
  }

  > .tippy-backdrop {
    background-color: #1f262d;
  }

  > .tippy-svg-arrow {
    fill: #1f262d;
  }
}
